
class CartService {

    addToLocalCart(item, quantity, option) {
        var localCart = JSON.parse(localStorage.getItem("zavese-cart"));
        if(localCart === undefined || localCart === null){
            localCart = []
        }
        localCart.push({ item: item, quantity: quantity, option: option })
        localStorage.setItem("zavese-cart", JSON.stringify(localCart))
        console.log("new cart:")
        console.log(localCart)
    }

    addFabricToLocalCart(item, quantity, option) {
        var localCart = JSON.parse(localStorage.getItem("zavese-cart"));
        if(localCart === undefined || localCart === null){
            localCart = []
        }
        localCart.push({ item: item, quantity: quantity, option: option })
        localStorage.setItem("zavese-cart", JSON.stringify(localCart))
        console.log("new cart:")
        console.log(localCart)
    }

    addBlindsToLocalCart(item, width, height, side, option) {
        var localCart = JSON.parse(localStorage.getItem("zavese-cart"));
        if(localCart === undefined || localCart === null){
            localCart = []
        }
        localCart.push({ item: item, width: width, height: height, side: side, option: option })
        localStorage.setItem("zavese-cart", JSON.stringify(localCart))
        console.log("new cart:")
        console.log(localCart)
    }

    getCart(){
        return JSON.parse(localStorage.getItem("zavese-cart"));
    }

    removeFromCart(item){
        var localCart = JSON.parse(localStorage.getItem("zavese-cart"));
        if(localCart === undefined || localCart === null || localCart === []){
            return;
        }
        for (var i = 0; i<localCart.length; i++){
            if (JSON.stringify(localCart[i]) === JSON.stringify(item)) {
                localCart.splice(i, 1);
            }
        }
        localStorage.setItem("zavese-cart", JSON.stringify(localCart))
    }

    clearCart(){
        localStorage.clear()
    }

}

export default new CartService();
